// Backgrounds
$background: #313131;
$background-surface: #121212;
$background-error: #CF6679;
$background-card-1: rgba(255, 255, 255, 0.05);
$background-card-2: rgba(255, 255, 255, 0.10);
$background-overlay: rgba(31, 31, 31, 0.95);

// Colors
$default-color: white;
$on-primary: black;
$low-emphasis-color: rgba(255, 255, 255, 0.65);

$primary: #FFAB91;
$primary-variant: #E64919;
$secondary: #7DD7F2;

* {
    box-sizing: border-box;
}

/* Body background */
body {
    background-color: $background;
    font-family: 'Courier New', Courier, monospace;
}

// Smallest screens
@media screen and (max-width: 767px) {
    h1 {
        font-size: 44px;
    }

    h2 {
        font-size: 30px;
    }
}

// Medium screens
@media screen and (min-width: 768px) {
    h1 {
        font-size: 80px;
    }

    h2 {
        font-size: 40px;
    }

}

// Max screens
@media screen and (min-width: 992px) {
    h1 {
        font-size: 100px;
    }

    h2 {
        font-size: 50px;
    }
}

// Sections
.sections {

    // Standard section container styles
    %section-container-styles {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    // Section container
    .section-container {
        @extend %section-container-styles;
        padding-top: 7vh;
        margin-bottom: 25vh;

        .section-content {
            width: 100%;
        }
    }

    // Full screen section
    .section-container-full {
        @extend %section-container-styles;
        min-height: 100vh;
    }

    // section content
    .section-content {
        // No styles
    }

    // Section header
    .section-header {
        color: $default-color;
        margin-bottom: 1rem;

        h1 {
            color: $primary;
        }
    }

    // Section body
    .section-body {
        color: $default-color;
    }

    // Section photo
    .section-photo {
        // No styles
    }
}

// Social Icon Link Styles
.social-icon {
    // Center link in parent
    display: flex;
    align-items: center;
    justify-content: center;

    // Link styles
    a {
        // Link styles
        display: inline-block;
        text-decoration: none;
        color: $primary-variant;
        transition: color 0.3s ease-in-out;

        // Hover effect
        &:hover {
            color: $primary;
        }

        // Align font awesome icons in link
        i {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

// Arrow buttons (forward user to next page)
.arrow-button {
    // Spacing above icon
    margin-top: 4vh;

    // Link
    a {
        display: inline-block;
        text-decoration: none;

        span {
            color: $default-color;

            display: inline-block;
            padding: 20px;
            font-size: 20px;

            border: 2px solid $default-color;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;

            &:hover {
                padding: 30px;
                color: $primary;
                border: 2px solid $primary;
                background-color: rgba($primary, 0.05);
            }
        }
    }
}

// Skill styles
.skill {
    display: block;
    padding: 2px 5px;
    margin: 10px 10px 0 0;
    color: $default-color;

    border: 2px solid $primary;
    border-radius: 5px;
    background-color: rgba($primary-variant, 0.65);
}