@use '../../App';

#contact {
    padding: 2vh 0;
    color: App.$default-color;
    background-color: App.$background-overlay;
    border: 2px solid App.$background-overlay;
    border-radius: 5px;
    transition: border 0.3s ease-in-out;

    &:hover {
        border: 2px solid App.$primary;
    }
}