@use '../../App';

// Footer styles
.footer {
    // Top/Bottom margin
    margin: 3vh 0;

    // Link styles
    a {
        text-decoration: none;
        color: App.$low-emphasis-color;
        transition: color 0.3s;

        // Hover on link
        &:hover {
            color: App.$primary;
        }
    }
}