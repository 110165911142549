@use '../../App.scss';

.navbar {
    background-color: App.$background-overlay;
    border-bottom: 2px solid App.$primary;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.navbar-toggler {
    border: none;

    &:focus {
        box-shadow: none;
    }
}

.navbar-toggler-icon {
    filter: invert(100%) brightness(1000%);
}

.logo-container {

    .logo {
        display: inline;
        padding: 6px;
        color: App.$primary;
        border: 2px solid App.$primary;
        border-radius: 50%;
        transition: all 0.2s;

        &:hover {
            background-color: rgba(App.$default-color, 0.1);
            border: 2px solid App.$default-color;
            color: App.$default-color;
        }
    }
}

.n-link {
    text-decoration: none;
    color: App.$default-color;
    margin: 3px 10px;
    font-size: 20px;

    &:hover {
        color: App.$primary;
    }
}

#resume {
    padding: 6px;
    border: 2px solid App.$primary;
    border-radius: 5px;

    transition: all 0.2s;

    &:hover {
        background-color: rgba(App.$default-color, 0.05);
    }
}